<template>
    <div>
        <p v-if="props.useToken" class="mt-5">
            {{ $t("label.setupPassword") }}
        </p>

        <form>
            <div class="form-control-group" :class="!disable || password.length > 6 ? 'text-success' : 'text-danger'">
                <label for="password" :class="!disable || password.length > 6 ? 'text-success' : 'text-danger'">{{
                    $t("user.new_password")
                }}</label>
                <input
                    id="password"
                    :value="password"
                    :class="!disable || password.length > 6 ? 'text-success' : 'text-danger'"
                    type="password"
                    placeholder="******"
                    class="form-control"
                    required
                    @input="(e) => (password = (e.target as HTMLInputElement).value)"
                />
            </div>

            <div class="form-control-group" :class="disable ? 'text-danger' : 'text-success'">
                <label for="password_confirm" :class="disable ? 'text-danger' : 'text-success'">{{
                    $t("user.confirm_password")
                }}</label>
                <input
                    id="password_confirm"
                    :value="passwordConfirm"
                    type="password"
                    placeholder="******"
                    class="form-control"
                    :class="disable ? 'text-danger' : 'text-success'"
                    required
                    @input="(e) => (passwordConfirm = (e.target as HTMLInputElement).value)"
                />
            </div>

            <div class="mt-5">
                <div class="form-control-row">
                    <button type="submit" class="btn btn-primary w-100" :disabled="disable" @click="save">
                        {{ $t("btn.save") }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
const router = useRouter();
type Props = {
    useToken: boolean;
};
const { apm } = useApm();
const { t } = useI18n();

const toast = useToast();
const axios = useAxios();

const props = withDefaults(defineProps<Props>(), {
    useToken: false
});

const password = ref("");
const passwordConfirm = ref("");
const disable = ref(true);

watch([password, passwordConfirm], () => {
    disable.value = !(password.value.length > 6 && password.value === passwordConfirm.value);
});

async function save(event: Event) {
    event.preventDefault();

    if (password.value !== passwordConfirm.value) {
        toast.error(t("auth.confirm.passwordsDontMatch"));
        return;
    }

    if (password.value.length < 6) {
        toast.error(t("auth.confirm.passwordTooShort"));
        return;
    }

    if (!props.useToken) {
        try {
            await axios.post("/api/auth/change-password", {
                password: password.value,
                password_confirm: passwordConfirm.value
            });

            await navigateTo({ name: "auth-logout" });
        } catch (error) {
            if (error instanceof Error) {
                toast.error(error.message);
            } else {
                toast.error(String(error));
            }
        }
    } else {
        await axios
            .post("/api/auth/changePasswordByToken", {
                password: password.value,
                password_confirm: passwordConfirm.value,
                token: router.currentRoute.value.query.token
            })
            .then(async () => {
                await navigateTo({ name: "auth-logout" });
            })
            .catch(async (error) => {
                if (error.response.data.message) {
                    toast.error(t(String(error.response.data.message)));
                    apm.captureError(String(error));
                }
                await navigateTo({ name: "auth-login" });
            });
    }
}
</script>
